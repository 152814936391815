import { StarFilled, StarTwoTone } from "@ant-design/icons";
import { Col, Grid, List, Row, Spin } from "antd";
import backgroundImg from "../../assents/background2.png";
import profileBackground from "../../assents/profileBackground.png";
import profilePic from "../../assents/pro.png";
import { Image } from "antd";
import AppFooter from "../../components/footer";
import PhotoCollage from "../../components/photoCollage";
import NavbarProfile from "../../components/NavBarProfile";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PlayerContext } from "../../context/player.context";
import axiosInstance from "../../axiosInstance";
import { useArcade } from "../../context/Arcade.context";
import { useCoach } from "../../context/coach.context";
import NavbarLogin from "../../components/NavBarLogin";

interface PlayerData {
  role?: string;
  firstname?: string;
  lastname?: string;
  email?: string;

  // add other properties as needed
}
const PlayerProfileUser = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { playerId } = useParams(); // Use playerId, not playerID
  console.log(playerId);
  const [playerData, setPlayerData] = useState<PlayerData | null>(null);
  const { userDetails } = useContext(PlayerContext);
  const { managerDetails } = useArcade();
  const { coachDetails } = useCoach();
  const [firstname, setFirstname] = useState(userDetails?.firstName);
  const [lastname, setLastname] = useState(userDetails?.lastName);
  const [discription, setDiscription] = useState(userDetails?.discription);
  const [achivements, setAchivements] = useState(userDetails?.achivements);
  const [user_image, setUser_image] = useState(userDetails?.image);
  const AchivementsGetToArry = (achivements: string) => {
    if (achivements) {
      return achivements.split(",");
    }
    return [];
  };
  useEffect(() => {
    // setIsLoading(true);
    axiosInstance
      .get(`/api/auth/getplayerdetailsUser/${playerId}`, {})
      .then((res) => {
        console.log(res.data);
        setFirstname(res.data.firstname);
        setLastname(res.data.lastname);
        setDiscription(res.data.Discription);
        setUser_image(res.data.user_image);
        const achiv = res.data.achivement;
        let achiveArr: string[] = [];
        achiv.map((item: any) => {
          achiveArr.push(item.achivement_details as string);
        });
        setAchivements(achiveArr.join(","));
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userDetails]);

  const { useBreakpoint } = Grid;
  const { lg, md, sm, xs } = useBreakpoint();
  return (
    <>
      {userDetails.id !== "" ||
      managerDetails.id !== "" ||
      coachDetails.id !== "" ? (
        <NavbarProfile />
      ) : (
        <NavbarLogin />
      )}

      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
      </style>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={10}
          xl={10}
          style={{
            marginTop: "50px",
            backgroundImage: `url(${profileBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Image width={300} src={user_image} preview={{ src: user_image }} />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={14}
          xl={14}
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "1000px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              height: "800px",

              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <Spin spinning={isLoading}>
              <div>
                <h1
                  style={{
                    color: "#000",

                    fontSize: "32px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontFamily: "kanit",
                    lineHeight: "normal",
                    marginBottom: "0px",
                  }}
                >
                  {firstname} {lastname}
                </h1>
                <p
                  style={{
                    margin: "0px",
                    color: "#000",
                    fontFamily: "kanit",

                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  First class rugby coach
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <p
                      style={{
                        color: "#0E458E",
                        fontFamily: "kanit",
                        fontSize: "39px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        margin: "0px",
                      }}
                    >
                      5.0
                    </p>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "auto",
                        position: "relative",
                        width: "max-content",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <StarFilled style={{ color: "#0E458E" }} />
                        <StarFilled style={{ color: "#0E458E" }} />
                        <StarFilled style={{ color: "#0E458E" }} />
                        <StarTwoTone twoToneColor="#0E458E" />
                        <StarTwoTone twoToneColor="#0E458E" />
                      </div>
                      <p
                        style={{
                          color: "#000",
                          opacity: "0.64",
                          fontFamily: "kanit",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          margin: "0px",
                        }}
                      >
                        120 Feedbacks
                      </p>
                    </div>{" "}
                  </Col>
                </Row>
              </div>
              <p
                style={{
                  color: "#000",
                  fontFamily: "kanit",
                  opacity: ".96",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "normal",
                  marginTop: "0px",
                }}
              >
                {discription}
              </p>
              <p
                style={{
                  color: "#000",
                  fontFamily: "kanit",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  marginTop: "0px",
                }}
              >
                Achivements
              </p>
              <List
                style={{
                  padding: "0px",
                  fontWeight: "200",
                  color: "#000",
                  fontFamily: "kanit",
                  lineHeight: "0.4",
                }}
                itemLayout="horizontal"
                dataSource={AchivementsGetToArry(achivements)}
                renderItem={(item) => (
                  <List.Item
                    style={{
                      position: "relative",

                      listStyle: "none",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "kanit",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        &#8226;
                      </span>
                      {item}
                    </div>
                  </List.Item>
                )}
              />
            </Spin>
          </div>
        </Col>
      </Row>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "60px",
        }}
      >
        <p
          style={{
            alignItems: "center",
            color: "#0E458E",
            fontFamily: "kanit",
            fontWeight: "500",
            fontSize: "32px",
            paddingBottom: "10px",
            marginBottom: "0px",
          }}
        >
          Photos
        </p>
      </div>
      <div
        style={{
          width: "95%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      ></div>
      <PhotoCollage />
      <AppFooter />
    </>
  );
};

export default PlayerProfileUser;
