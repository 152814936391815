import { Col, Row, Button, Modal, Spin, Empty } from "antd";
import React, { useState, useEffect } from "react";
// import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import { AdvancedImage } from "@cloudinary/react";
import { useNavigate } from "react-router-dom";
import { Cloudinary } from "@cloudinary/url-gen";

interface Player {
  player_id: string;
  user: {
    user_id: string;
    firstname: string;
    lastname: string;
    email: string;
    DOB: string;
    gender: string;
    accountNumber: string | null;
    is_active: string;
    user_image: string | null;
    address: String | null;
    city: String | null;
    country: String | null;
  };
}

const PlayerManagement = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Fetch player data from the backend when the component mounts
    const fetchPlayers = async () => {
      try {
        const response = await axiosInstance.get("/api/getplayer");
        setPlayers(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error("Error fetching players:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);
  const filteredPlayers = players.filter(
    (players) =>
      players.player_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      players.user.firstname
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      players.user.lastname.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Col
      span={19}
      style={{ backgroundColor: "#EFF4FA", padding: "2%", marginLeft: "21%" }}
    >
      {" "}
      <Spin spinning={loading}>
        <Row>NAV</Row>
        <Row>
          <Col style={{ color: "#0E458E" }}>
            <h2>Players details</h2>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <input
              style={{ width: "100%", height: "40px" }}
              type="search"
              placeholder="Search here"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Col>
        </Row>

        {filteredPlayers.length === 0 ? (
          <div>
            <Empty description={"No Player Found"} />
          </div>
        ) : (
          filteredPlayers.map((player: Player) => (
            <DataRow playerdetails={player} />
          ))
        )}
      </Spin>
    </Col>
  );
};

export default PlayerManagement;

function DataRow(props: any) {
  const { playerdetails } = props;
  // console.log(playerdetails);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [cloudName] = useState("dle0txcgt");
  const cld = new Cloudinary({
    cloud: {
      cloudName,
    },
  });
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/PlayerUser/:${playerdetails.player_id}`);
  };
  return (
    <Row
      key={playerdetails.player_id}
      style={{
        backgroundColor: "white",
        padding: "1%",
        marginTop: "20px",
      }}
    >
      <Col></Col>
      <Col span={8} style={{}}>
        <AdvancedImage
          onClick={handleClick}
          style={{
            borderRadius: "50%",
            position: "absolute",
            width: "80px",
            height: "80px",
          }}
          cldImg={
            cld.image(playerdetails.user.user_image || "default.jpg")
            // .resize(Resize.crop().width(200).height(200).gravity('auto'))
            // .resize(Resize.scale().width(200).height(200))
          }
          // border-radius: 50%;
          // width: 80px;
          // height: 80px;
        />
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            marginLeft: "100px",
            alignItems: "center",
            textAlign: "center",
            height: "80px",
            fontSize: "16px",
          }}
        >
          {playerdetails.user.firstname} {playerdetails.user.lastname}
          {/* Mr.Ruchith Nusara */}
        </div>
      </Col>

      <Col span={8}></Col>
      <Col span={8} style={{}}>
        <div
          style={{
            height: "80px",
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            style={{ width: "100px", backgroundColor: "#0E458E" }}
            onClick={showModal}
          >
            <div
              style={{
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Details
            </div>
          </Button>

          <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ lineHeight: 3.0, fontSize: "160px" }}>
              <Row>
                <div style={{ fontSize: "28px", color: "#0E458E" }}>
                  Player Details
                </div>
                <Col span={24}>
                  <b>Player ID : </b> {playerdetails.player_id}
                </Col>
                <Col span={24}>
                  <b>First Name :</b> {playerdetails.user.firstname}
                </Col>
                <Col span={24}>
                  <b>Last Name : </b> {playerdetails.user.lastname}
                </Col>
                <Col span={24}>
                  <b>Email :</b> {playerdetails.user.email}
                </Col>
                <Col span={24}>
                  <b>Account Number :</b>{" "}
                  {playerdetails.user.accountNumber || "N/A"}
                </Col>

                <Col span={24}>
                  <b>City :</b> {playerdetails.user.city || "N/A"}
                </Col>
                <Col span={24}>
                  <b>Country :</b> {playerdetails.user.country || "N/A"}
                </Col>
              </Row>
            </div>
          </Modal>

          <Button
            type="primary"
            ghost
            style={{ width: "100px", marginLeft: "20px" }}
          >
            <div
              style={{
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Delete
            </div>
          </Button>
        </div>
      </Col>
    </Row>
  );
}
